<template>
  <CRow>
    <CCol col="12" sm="12">
      <CCard>
        <CCardHeader>
          {{ $route.name }}
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol col="12" sm="10"></CCol>
            <CCol col="12" sm="2">
              <CButton color="primary" block @click="getContents()">{{ $t('search') }}</CButton>
            </CCol>
          </CRow>
          <hr>
          <CRow>
            <CCol col="12" sm="10" class="d-flex align-items-center">
              <font class="text-muted">{{ $t('search_info', searchInfo) }}</font>
            </CCol>
            <CCol col="12" sm="2"></CCol>
          </CRow>
          <hr>
          <v-client-table :columns="columns" :data="data" :options="options">
            <font slot="updated_at" slot-scope="props">
              {{ $moment(props.row.updated_at).format('YYYY-MM-DD HH:mm:ss') }}
            </font>
            <div slot="action" slot-scope="props" class="text-center">
              <CButtonGroup>
                <CButton color="warning" v-bind="{ variant: 'outline' }" @click="() => editContentModal = { data: props.row, modal: true }">{{ $t('edit') }}</CButton>
              </CButtonGroup>
            </div>
          </v-client-table>
        </CCardBody>
      </CCard>

      <EditContentModal :data="editContentModal.data" :show="editContentModal.modal" @showUpdate="show => editContentModal.modal = show" @formSubmit="getContents()" />

    </CCol>
  </CRow>
</template>

<script>
import EditContentModal from './modal/EditContentModal'

export default {
  name: 'contents',
  components: {
    EditContentModal,
  },
  data () {
    return {
      columns: [ 'name', 'updated_at', 'action' ],
      data: [],
      options: {
        headings: {
          name: this.$t('name'),
          updated_at: this.$t('update_time'),
          action: this.$t('action')
        },
        sortable: [ 'name', 'updated_at' ],
        filterable: [ 'name', 'updated_at' ]
      },
      searchInfo: { at: '-', count: 0, sec: 0 },
      searchData: {},
      editContentModal: { data: {}, modal: false },
    }
  },
  computed: {
  },
  mounted: function () {
    this.getContents()
  },
  methods: {
    getContents () {
      const startMoment = this.$moment()
      const loader = this.$loading.show()
      this.$store.dispatch('getContents', this.$_.clone(this.searchData)).then(res => {
        this.data = res
        this.searchInfo.at = this.$moment().format('YYYY-MM-DD HH:mm:ss')
        this.searchInfo.count = res.length
        this.searchInfo.sec = this.$moment().diff(startMoment) / 1000
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    }
  }
}
</script>
